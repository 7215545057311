<template>
  <div class="dilu-page">
    <!-- 搜索区域 -->
    <div class="search">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="日志名称">
          <el-select v-model="searchParams.apiName" style="width:200px;" filterable clearable>
            <el-option v-for="(item, index) in apiList" :key="index" :label="item.apiName" :value="item.apiName"/>
          </el-select>
        </el-form-item>
        <el-form-item label="请求地址">
          <el-select v-model="searchParams.requestUrl" style="width:250px;" filterable clearable>
            <el-option v-for="(item, index) in apiList" :key="index" :label="item.requestUrl" :value="item.requestUrl"/>
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间">
          <DiluPicker :start.sync="searchParams.startAt" :stop.sync="searchParams.stopAt"/>
        </el-form-item>
        <el-form-item label="参数内容">
          <el-input v-model="searchParams.requestParam"/>
        </el-form-item>
        <el-form-item label="执行时长">
          <el-input onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" v-model="searchParams.duration"/>
        </el-form-item>
        <el-form-item label="操作人员">
          <el-input v-model="searchParams.userName"/>
        </el-form-item>
      </el-form>
      <div class="search-submit">
        <el-button :style="{ color: buttonTextColor }" icon="el-icon-search" type="primary" @click="search">搜索</el-button>
        <el-button :style="{ color: buttonTextColor }" icon="el-icon-refresh" type="primary" @click="reset">重置</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" :total="total" @currentChange="currentChange">
      <el-table-column prop="date" label="#" width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="操作人" width="150" align="left"/>
      <el-table-column prop="apiName" label="日志名称" width="250" align="left"/>
      <el-table-column prop="requestIp" label="请求来源" width="150" align="left"/>
      <el-table-column prop="duration" label="执行时长" width="150" align="left"/>
      <el-table-column prop="createdAt" label="操作时间" width="200" align="left"/>
      <el-table-column label="操作" align="center" fixed="right" width="150">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="detail(scope.row)" type="text">查看参数</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>
    <!-- 弹窗 -->
    <el-dialog title="请求参数" :visible.sync="visible" width="600px" padding-top="10px">
      <JsonView :json="jsonData"></JsonView>
    </el-dialog>
  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import DiluPicker from "@/components/public/dilu-picker.vue";
import JsonView from "@/components/view/JsonView.vue";
import { resetDate, fun_date } from "@/utils/util/validate";
import { mapState } from "vuex";
export default {
  data() {
    return {
      apiList: [],
      searchParams: {
        pageNum: 1, // 页码
        pageSize: 20, // 每页条数
        apiName: "", // 日志名称
        compare: "", // 比较条件
        startAt: "", //开始日期
        stopAt: "", //结束日期
        duration: "", // 执行时长
        userName: "", // 操作人
        requestUrl: "", // 请求地址
        requestParam: "", // 请求参数
      },
      jsonData: {},
      visible: false,
      option: [],
      tableData: [],
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      total: 0,
      isSerch: false, //是否在查询
    };
  },
  components: {
    DiluTable,
    DiluPicker,
    JsonView
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.searchParams.stopAt = resetDate(new Date());
    this.searchParams.startAt = fun_date(-7);
    this.apiDefine();
    this.admList();
  },
  methods: {
     //获取列表
     apiDefine() {
      this.request.apiList({}).then((res) => {
        this.apiList = res.data;
      });
    },
    //查看参数
    detail(e) {
      if (e.requestParam=="") {
        this.$message.error("没有请求参数");
      } else {
        this.jsonData = JSON.parse(e.requestParam);
        this.visible = true;
      }
    },
    //搜索
    search() {
      this.isSerch = true; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.admList();
    },
    // 翻页
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.admList();
    },
    // 重置
    reset() {
      this.isSerch = false; //是否在查询
      this.searchParams = {
        apiName: "", // 日志类别
        compare: "", // 比较条件
        startAt: "", //开始日期
        stopAt: "", //结束日期
        duration: "", // 执行时长
        pageNum: 1, // 页码
        pageSize: 10, // 每页条数
        userName: "", // 操作人
        requestUrl: "", // 请求地址
        requestParam: "", // 请求参数s
      };
      this.total = 0;
      this.admList();
    },
    // 获取日志列表
    admList() {
      this.request.admList(this.searchParams).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
  },
};
</script>

<style scoped>
</style>
<template>
   <div class="dilu-page">
 
     <!-- 搜索区域 -->
     <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
         <el-form-item label="公司全称">
            <el-input v-model="searchParams.fullName" clearable/>
         </el-form-item>
         <el-form-item label="租户域名">
            <el-input v-model="searchParams.platformDomain" clearable/>
         </el-form-item>
         <el-form-item label="所属区域" style="width:250px;">
          <el-select v-model="searchParams.region" clearable>
            <el-option label="中国区" value="cn"/>
            <el-option label="欧洲区" value="eur"/>
          </el-select>
         </el-form-item>
       </el-form>
       <div class="search-submit">
         <el-button
           :style="{ color: buttonTextColor }"
           icon="el-icon-search"
           type="primary"
           @click="search"
           >搜索</el-button
         >
         <el-button
           :style="{ color: buttonTextColor }"
           icon="el-icon-refresh"
           type="primary"
           @click="reset"
           >重置</el-button
         >
       </div>
     </div>
     
     <!-- 按钮区域 -->
     <div class="operation-div">
       <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新建租户</el-button>
       <el-button :style="{ color: buttonTextColor }" icon="el-icon-refresh" type="primary" @click="syncData">数据同步</el-button>
     </div>
 
     <!-- 表格 -->
     <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
       <el-table-column prop="fullName" label="公司全称" align="left" width="260"/>
       <el-table-column prop="shortName" label="公司简称" align="left" width="150"/>
       <el-table-column prop="platformDomain" label="平台域名" align="left" width="190"/>
       <el-table-column prop="platformName" label="平台名称" align="left" width="190"/>
       <el-table-column prop="status" label="租户状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status==10">正常</span>
          <span v-if="scope.row.status==20">已过期</span>
        </template>
       </el-table-column>
       <el-table-column prop="expireDate" label="续费到期" align="center" :formatter="formatDate" width="100"/>
       <el-table-column label="操作" align="center" fixed="right" width="150">
         <template slot-scope="scope">
           <div class="table-operation">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">操作<i class="el-icon-arrow-down el-icon--right"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ type: 'detail', data: scope.row }">查看</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'edit', data: scope.row }">编辑</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'share', data: scope.row }">一键分享</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'resetPassword', data: scope.row }">重置密码</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'menu', data: scope.row }">分配权限</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'product', data: scope.row }">分配产品</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'renew', data: scope.row }">账号续费</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
           </div>
         </template>
       </el-table-column>
     </DiluTable>

     <AccountShare :shareInfo="shareInfo" :shareVisible="shareVisible" @shareClose="shareClose"/>

     <SelectMenu :tenantId="tenantId" :shortName="shortName" :menuVisible="menuVisible" @menuClose="menuClose"/>

     <SelectProduct :tenantId="tenantId" :shortName="shortName" :productVisible="productVisible" @productClose="productClose"/>
 
   </div>
 </template>
 
 
 <script>
 import DiluTable from "@/components/public/dilu-table.vue";
 import AccountShare from "./module/accountShare";
 import SelectMenu from "./module/selectMenu";
 import SelectProduct from "./module/selectProduct";
 import { mapState } from "vuex";
 export default {
   data() {
     return {
       searchParams: {
         platformDomain: "", //租户域名
         fullName: "",       //租户全称 
         region: "",         //所属区域
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
       },
       menuVisible: false,
       productVisible: false,
       tenantId: "",
       shortName: "",
       shareVisible: false,
       shareInfo: {},
       isSearch: false, //是否在查询
       tableConfig: {
         isTree: false,
         isPage: true,
       },
       tableData: [],
       total: 0,
     };
   },
   components: {
     DiluTable,
     AccountShare,
     SelectMenu,
     SelectProduct
   },
   computed: {
     ...mapState(["buttonTextColor"]),
   },
   mounted() {
     this.queryList();
   },
   methods: {
     //数据同步
     syncData() {
      this.request.syncData().then((res) => {
        if (res.code==0) {
          this.$message.success("同步完成");
        }
      });
     },
     //操作
     handleCommand(e) {
        if (e.type == "detail") {
          this.detail(e.data);
        }
        if (e.type == "edit") {
          this.edit(e.data);
        }
        if (e.type == "share") {
          this.share(e.data);
        }
        if (e.type == "resetPassword") {
          this.resetPassword(e.data);
        }
        if (e.type == "menu") {
          this.menu(e.data);
        }
        if (e.type == "product") {
          this.product(e.data);
        }
        if (e.type == "renew") {
          this.renew(e.data);
        }
     },
     formatDate(row) {
        return row.expireDate.substring(0,10);
     },
     //翻页
     currentChange(e) {
       this.searchParams.pageNum = e;
       this.queryList();
     },
     //新增
     add() {
      this.$router.push({
        path: "/tenant/tenantmgt/tenantNew",
        query: {
          isAdd: "1",
        },
      });
     },
     //编辑
     edit(e) {
      this.$router.push({
        path: "/tenant/tenantmgt/tenantNew",
        query: {
          isAdd: "2",
          currentData: e
        },
      });
     },
     //查看
     detail(e) {
      this.$router.push({
        path: "/tenant/tenantmgt/tenantNew",
        query: {
          isAdd: "3",
          currentData: e
        },
      });
     },
     //关闭分享弹窗
     shareClose() {
      this.shareVisible = false;
     },
     //分享账号
     share(e) {
      var params={};
      params['id']=e.id;
      this.request.tenantAccountShare(params).then((res) => {
        this.shareInfo = {
          password: res.data.password,
          account: res.data.account,
          domain: res.data.domain,
          more: res.data.more
        };
        this.shareVisible = true;
      });
     },
     product(e) {
      this.tenantId=e.id;
      this.shortName=e.shortName;
      this.productVisible = true;
     },
     //重置密码
     resetPassword(e) {
      this.$confirm("确认随机重置密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.request.resetTenantPassword({id:e.id}).then((res) => {
          if (res.code==0) {
            this.queryList();
            this.$message({
              type: "success",
              message: "重置成功!",
            });
          }
        });
      });
     },
     //关闭产品弹窗
     productClose() {
      this.productVisible = false;
     },
     //关闭菜单弹窗
     menuClose() {
      this.menuVisible = false;
     },
     //权限菜单
     menu(e) {
      this.tenantId=e.id;
      this.shortName=e.shortName;
      this.menuVisible = true;
     },
     //续费管理
     renew(e) {
      this.$message.error("暂未实现");
     },
     //搜索
     search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.queryList();
     },
     //重置
     reset() {
       this.isSearch = false; //是否在查询
       this.searchParams.pageNum = 1;
       this.searchParams.pageSize = 20;
       this.total = 0;
       this.queryList();
     },
     //查询列表
     queryList() {
       this.request.queryList(this.searchParams).then((res) => {
         this.tableData = res.data;
         this.total = res.dataMaxCount;
       });
     },
   },
 };
 </script>
 
 <style scoped>
 </style>
<template>
  <div class="dilu-page">
    <div class="search">
      <el-form :inline="true" :model="searchParams">
        <el-form-item label="类别" prop="deviceType">
          <el-select v-model="searchParams.deviceType" style="width:150px" clearable>
            <el-option label="4G模块" value=10 />
            <el-option label="DTU网关" value=20 />
            <el-option label="边缘服务器" value=30 />
          </el-select>
        </el-form-item>
        <el-form-item label="批次" style="margin-left:20px;">
          <el-input v-model="searchParams.batchCode" style="width:150px"/>
        </el-form-item>
        <el-form-item label="备注" style="margin-left:20px;">
          <el-input v-model="searchParams.remark" style="width:150px"/>
        </el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getBatchList" style="margin-right:30px;margin-left:30px;">搜索</el-button>
        <el-button type="primary" icon="el-icon-document-add" @click="add">新增批次号</el-button>
      </el-form>
    </div>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="deviceType" label="设备类型" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType==10">4G模块</span>
          <span v-if="scope.row.deviceType==20">DTU网关</span>
          <span v-if="scope.row.deviceType==30">边缘服务器</span>
        </template>
      </el-table-column>
      <el-table-column prop="batchCode" label="批次编号" width="120" align="center"/>
      <el-table-column prop="planCount" label="计划生产" width="100" align="center"/>
      <el-table-column prop="bindSuccess" label="绑定成功" width="110" align="center"/>
      <el-table-column prop="bindFail" label="绑定失败" width="110" align="center"/>
      <el-table-column prop="createdBy" label="创建人" width="120" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" width="180" align="center"/>
      <el-table-column prop="remark" label="备注信息" width="200" align="left"/>
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="deleteBatch(scope.row)" type="text">删除</el-button>
            <el-button @click="updateBatch(scope.row)" type="text">更新</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>
    <el-dialog title="新增批次" :visible.sync="editVisible" width="600px" :close-on-click-modal="false">
      <div class="dialog-content">
        <el-form :model="batchForm" :rules="rules" ref="batchForm">
          <el-form-item label="设备类别：" prop="deviceType" label-width="100px">
            <el-select v-model="batchForm.deviceType" style="width:430px" clearable>
              <el-option label="4G模块" value=10 />
              <el-option label="DTU网关" value=20 />
              <el-option label="边缘服务器" value=30 />
            </el-select>
          </el-form-item>
          <el-form-item label="生产批次：" prop="batchCode" label-width="100px">
            <el-input v-model.trim="batchForm.batchCode" style="width:430px" maxlength="24" clearable/>
          </el-form-item>
          <el-form-item label="计划生产：" prop="planCount" label-width="100px">
            <el-input v-model.trim="batchForm.planCount" style="width:430px" min=1 clearable/>
          </el-form-item>
          <el-form-item label="备注信息：" prop="remark" label-width="100px">
            <el-input v-model="batchForm.remark" style="width: 430px" maxlength="128" clearable/>
          </el-form-item>
          <div>&nbsp;</div>
          <el-button type="primary" @click="submitForm('batchForm')" style="width:90%;margin-left:5%;margin-right:5%;">提交保存</el-button>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      searchParams: {
        batchCode: null,
        deviceType: null,
        remark: "",
        pageNum: 1,
        pageSize: 15,
      },
      batchForm: {
        planCount: 0,
        batchCode: null,
        deviceType: null,
        remark: null,
      },
      rules: {
        deviceType: [{ required: true, message: "请输入设备类别", trigger: "blur" }],
        batchCode: [{ required: true, message: "请输入批次号", trigger: "blur" }],
        planCount: [{ required: true, message: "请输入计划生产", trigger: "blur" }],
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      addOrEdit: 0,
      editVisible: false, 
      tableData: [],
      total: 0,
    };
  },
  computed: {},
  components: {
    DiluTable
  },
  mounted() {
    this.getBatchList();
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.request.addBatch(this.batchForm).then((res) => {
            if (res.code==0) {
              this.$message({type: "success",message: "操作成功",});
              this.closeDialog();
              this.getBatchList();
            }
          });
        }
      });
    },
    closeDialog() {
      this.editVisible = false;
      this.batchForm = {
        batchCode: "",
        deviceType: "",
        remark: "",
      };
    },
    add() {
      this.batchForm = {
        batchCode: "",
        deviceType: "",
        remark: "",
      };
      this.editVisible = true;
      this.addOrEdit = 0;
    },
    deleteBatch(e) {
      this.request.deleteBatch({id:e.id}).then((res) => {
        if (res.code==0) {
          this.$message({type: "success",message: "操作成功",});
          this.getBatchList();
        }
      });
    },
    updateBatch(e) {
      this.request.updateBatch({id:e.id}).then((res) => {
        if (res.code==0) {
          this.$message({type: "success",message: "操作成功",});
          this.getBatchList();
        }
      });
    },
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.getBatchList();
    },
    getBatchList() {
      this.request.batchList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 12px 0;
  ::v-deep(.el-form) {
    .el-form-item--mini.el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      line-height: 40px;
    }
  }
}
</style>
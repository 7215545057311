<template>
  <div class="dilu-page">
    <div class="search">
      <el-form :inline="true" :model="ruleForm">
        <el-form-item label="批次" prop="batchCode">
          <el-select v-model="ruleForm.batchCode" style="width:200px;" filterable clearable>
            <el-option v-for="(item, index) in batchList" :key="index" :label="item.name" :value="item.batchCode"/>
          </el-select>
        </el-form-item>
        <el-form-item label="编号">
          <el-input v-model="ruleForm.gateway" style="width:170px;" @keyup.enter.native="handleKeyup" clearable/>
        </el-form-item>
        <el-button style="margin-left:15px;font-size:14px;" icon="el-icon-document-add" type="primary" @click="add">添加</el-button>
        <el-button style="margin-left:15px;font-size:14px;" icon="el-icon-document-delete" type="primary" @click="clear">清空</el-button>
        <span style="margin-left:15px;font-size:14px;">总数:{{total}}&nbsp;&nbsp;&nbsp;通过:{{success}}&nbsp;&nbsp;&nbsp;失败:{{failed}}</span>
      </el-form>
    </div>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :moveLast="moveLast">
      <el-table-column prop="index" label="#" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="testResult" width="180" label="测试结果" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.testResult==10" class="success">成功</span>
          <span v-if="scope.row.testResult==20" class="fail">失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="imei" width="150" label="网关编号" align="center"/>
      <el-table-column prop="imsi" width="150" label="IMSI" align="center"/>
      <el-table-column prop="iccid" width="180" label="ICCID" align="center"/>
      <el-table-column prop="firmware" width="150" label="版本号" align="center"/>
      <el-table-column prop="lastOnline" width="180" label="最近心跳" align="center"/>
      <el-table-column label="操作" align="center" width="80" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="del(scope.row)" type="text">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      ruleForm: {
        region: "cn",   //所属区域（cn：中国区，eur：欧洲区）
        batchCode: "",  //生产批号
        gateway: "",    //网关编号
      },
      tableConfig: {
        isTree: false,
        isPage: false,
      },
      moveLast: "yes",
      tableData: [],
      batchList: [],
      total: 0,
      success: 0,
      failed: 0
    };
  },
  components: {
    DiluTable
  },
  mounted() {
    this.allBatch();
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  methods: {
    handleKeyup() {
        if(this.ruleForm.gateway.length==15){
          this.add();
        }
    },
    allBatch() {
      this.request.allBatch({}).then((res) => {
        if (res.code==0) {
          this.batchList=res.data;
        }
      });
    },
    add() {
      const that=this;
      if (that.ruleForm.batchCode=="") {
        that.$message({type: "error",message: "请指定生产批次号"});
        return;
      }
      for (var i=0; i<that.tableData.length; i++) {
        if (that.tableData[i].imei==that.ruleForm.gateway) {
          that.$message({type: "error",message: "该编号已存在"});
          return;
        }
      }
      that.request.testGateway(that.ruleForm).then((res) => {
        if (res.code==0) {
          that.tableData.push(res.data);
          that.total=that.total+1;
          if (res.data.testResult==10) {
            that.success=that.success+1;
          } else {
            that.failed=that.failed+1;
          }
        }
        that.ruleForm.gateway="";
      });
    },
    del(e) {
      for (var i=0; i<this.tableData.length; i++) {
        if (this.tableData[i].imei==e.imei) {
          this.tableData.splice(i,1);
          this.total=this.total-1;
          if (e.testResult==10) {
            this.success=this.success-1;
          } else {
            this.failed=this.failed-1;
          }
          return;
        }
      }
    },
    clear() {
      this.tableData=[];
      this.total=0;
      this.success=0;
      this.failed=0;
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  background-color:green;
  color:white;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  border-radius:5px;
}
.fail {
  background-color:red;
  color:white;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  border-radius:5px;
}
.search {
  margin: 12px 0;
  ::v-deep(.el-form) {
    .el-form-item--mini.el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      line-height: 40px;
    }
  }
}
</style>
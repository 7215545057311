<template>
  <el-dialog :title="isAdd ? '新增协议' : '编辑协议'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="协议名称" prop="name">
          <el-input v-model="ruleForm.name" @input="onInput"/>
        </el-form-item>
        <el-form-item label="从机地址" prop="address">
          <el-input v-model="ruleForm.address" @input="onInput"/>
        </el-form-item>
        <el-form-item label="波特率" prop="baudrate">
          <el-input v-model="ruleForm.baudrate" @input="onInput"/>
        </el-form-item>
        <el-form-item label="数据位" prop="dataBits">
          <el-input v-model="ruleForm.dataBits" @input="onInput"/>
        </el-form-item>
        <el-form-item label="奇偶校验" prop="parityCheck">
          <el-select v-model="ruleForm.parityCheck" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="无" :value=0 />
            <el-option label="有" :value=1 />
          </el-select>
        </el-form-item>
        <el-form-item label="停止位" prop="stopBits">
          <el-input v-model="ruleForm.stopBits" @input="onInput"/>
        </el-form-item>
        <el-form-item label="轮询间隔" prop="polling">
          <el-input v-model="ruleForm.polling" @input="onInput"/>
        </el-form-item>
        <el-form-item label="返回超时" prop="timeout">
          <el-input v-model="ruleForm.timeout" @input="onInput"/>
        </el-form-item>
        <el-form-item label="访问限制" prop="accessLimits">
          <el-input v-model="ruleForm.accessLimits" @input="onInput"/>
        </el-form-item>
        <el-form-item label="地址类型" prop="addrType">
          <el-select v-model="ruleForm.addrType" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="十进制" :value=10 />
            <el-option label="十六进制" :value=16 />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>

import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        name: null,
        address: 1,
        baudrate: 9600,
        dataBits: 8,
        parityCheck: 0,
        stopBits: 1,
        polling: 1000,
        timeout: 2000,
        accessLimits: 120,
        addrType: 10,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请指定协议名称",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        if (this.isAdd) {
            //新增
            this.ruleForm.id = null;
            this.ruleForm.name = null;
            this.ruleForm.address = 1;
            this.ruleForm.baudrate = 9600;
            this.ruleForm.dataBits = 8;
            this.ruleForm.parityCheck = 0;
            this.ruleForm.stopBits = 1;
            this.ruleForm.polling = 1000;
            this.ruleForm.timeout = 2000;
            this.ruleForm.accessLimits = 120;
            this.ruleForm.addrType = 10;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.name = this.currentData.name;
          this.ruleForm.address = this.currentData.address;
          this.ruleForm.baudrate = this.currentData.baudrate;
          this.ruleForm.dataBits = this.currentData.dataBits;
          this.ruleForm.parityCheck = this.currentData.parityCheck;
          this.ruleForm.stopBits = this.currentData.stopBits;
          this.ruleForm.polling = this.currentData.polling;
          this.ruleForm.timeout = this.currentData.timeout;
          this.ruleForm.accessLimits = this.currentData.accessLimits;
          this.ruleForm.addrType = this.currentData.addrType;
        }
      }
    },
  },
  components: {},
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd"],
  methods: {
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    //处理关闭
    handleClose() {
      this.ruleForm = {
        id: null,
        name: null,
        address: null,
        baudrate: null,
        dataBits: null,
        parityCheck: null,
        stopBits: null,
        polling: null,
        timeout: null,
        accessLimits: null,
        addrType: null,
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交保存
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.originalAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("originalList");
                this.handleClose();
              }
            });
          } else {
            this.request.originalEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("originalList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>
<template>
  <div class="page-content">
    <el-form :inline="true" :model="searchParams" class="demo-form-inline">
      <div class="page-header">
        <el-form-item label="批次" style="margin-right:20px;">
          <el-input v-model="searchParams.batchCode" style="width:120px" clearable/>
        </el-form-item>
        <el-form-item label="结果"  style="margin-right:20px;">
          <el-select v-model="searchParams.testResult" style="width:120px" clearable>
            <el-option label="成功" value=10 />
            <el-option label="失败" value=20 />
            <el-option label="未测试" value=30 />
          </el-select>
        </el-form-item>
        <el-form-item label="网关" style="margin-right:20px;">
          <el-input v-model="searchParams.gateway" style="width:150px" clearable/>
        </el-form-item>
        <el-form-item label="时间">
          <DiluPicker :start.sync="searchParams.startAt" :stop.sync="searchParams.stopAt"/>
        </el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getGatewayList" style="margin-right:30px;margin-left:30px;">搜索</el-button>
      </div>
    </el-form>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="imei" width="150" label="网关编号" align="center"/>
      <el-table-column prop="imsi" width="150" label="IMSI" align="center"/>
      <el-table-column prop="iccid" width="180" label="ICCID" align="center"/>
      <el-table-column prop="firmware" width="150" label="版本号" align="center"/>
      <el-table-column prop="lastOnline" width="180" label="最近心跳" align="center"/>
      <el-table-column prop="testResult" width="120" label="测试结果" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.testResult==10" class="success">成功</span>
          <span v-if="scope.row.testResult==20" class="fail">失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="tester" width="120" label="测试人" align="center"/>
      <el-table-column prop="testDate" width="180" label="测试日期" align="center"/>
      <el-table-column prop="createdAt" width="180" label="创建日期" align="center"/>
    </DiluTable>
  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import DiluPicker from "@/components/public/dilu-picker.vue";
export default {
  data() {
    return {
      searchParams: {
        batchCode: null,
        gateway: null,
        testResult: null,
        startAt: null, 
        stopAt: null, 
        pageNum: 1,
        pageSize: 15,
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      tableData: [],
      total: 0,
    };
  },
  computed: {},
  components: {
    DiluTable,
    DiluPicker
  },
  mounted() {},
  methods: {
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.getGatewayList();
    },
    getGatewayList() {
      this.request.gatewayList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
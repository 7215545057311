<template>
  <el-dialog :title="isAdd ? '新增产品' : '编辑产品'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="产品类型" prop="category">
          <el-select v-model="ruleForm.category" style="width:400px;" @change="onChange" filterable clearable>
            <el-option v-for="(item, index) in catList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品型号" prop="model">
          <el-select v-model="ruleForm.model" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="生产厂商" prop="manufacturer">
          <el-select v-model="ruleForm.manufacturer" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in facList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="关联协议" prop="protocol">
          <el-select v-model="ruleForm.protocol" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in proList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称" prop="name">
          <el-input v-model="ruleForm.name"/>
        </el-form-item>
        <el-form-item label="详情页面" prop="detailPage">
          <el-select v-model="ruleForm.detailPage" size="small" style="width:400px;">
            <el-option label="device" value="/subcontract/project/equipment/device"/>
            <el-option label="equipmentSun" value="/subcontract/project/equipment/equipmentSun"/>
            <el-option label="equipmentMeter" value="/subcontract/project/equipment/equipmentMeter"/>
            <el-option label="equipmentNXRB" value="/subcontract/project/equipment/equipmentNXRB"/>
            <el-option label="equipmentWXYQ" value="/subcontract/project/equipment/equipmentWXYQ"/>
            <el-option label="equipmentAOXIN" value="/subcontract/project/equipment/equipmentAOXIN"/>
            <el-option label="equipmentYNNTSY" value="/subcontract/project/equipment/equipmentYNNTSY"/>
          </el-select>
        </el-form-item>
        <el-form-item label="启用状态" prop="status">
          <el-select v-model="ruleForm.status" size="small" style="width:400px;">
            <el-option label="启用" :value=10 />
            <el-option label="禁用" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="产品图片" prop="photo">
          <DiluUploadImg accept=".png,.jpg"  :limit="1" :fileList="photoList"  @remove="removePhoto" @success="successPhoto"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import DiluUploadImg from "@/components/public/dilu-uploadImg.vue";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        category: null,
        model: null,
        manufacturer: null,
        protocol: null,
        status: null,
        name: "",
        photo: "",
        detailPage: "",
      },
      photoList: [],
      catList: [],
      typeList: [],
      facList: [],
      proList: [],
      rules: {
        name: [
          {
            required: true,
            message: "请填写产品名称",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "请指定设备类型",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            message: "请指定设备型号",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            required: true,
            message: "请指定生产厂商",
            trigger: "blur",
          },
        ],
        protocol: [
          {
            required: true,
            message: "请关联产品协议",
            trigger: "blur",
          },
        ],
        photo: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: "blur",
          },
        ],
        detailPage: [
          {
            required: true,
            message: "请指定详情展示页面",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请指定上架状态",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        //新增
        if (this.isAdd) {
            this.ruleForm.id = null;
            this.ruleForm.category = null;
            this.ruleForm.model = null;
            this.ruleForm.manufacturer = null;
            this.ruleForm.protocol = null;
            this.ruleForm.name = "";
            this.ruleForm.photo = "";
            this.ruleForm.detailPage = "";
            this.ruleForm.status = 10;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.category = this.currentData.category;
          this.ruleForm.model = this.currentData.model;
          this.ruleForm.manufacturer = this.currentData.manufacturer;
          this.ruleForm.protocol = this.currentData.protocol;
          this.ruleForm.name = this.currentData.name;
          this.ruleForm.detailPage = this.currentData.detailPage;
          this.ruleForm.photo = this.currentData.photo;
          this.ruleForm.status = this.currentData.status;
          //产品图片
          let photo = [];
          photo.push({
            url: this.ruleForm.photo,
            name: "photo"
          });
          this.photoList = photo;
        }
        this.protocolList();
        this.categoryList();
        this.manufacturerList();
        if (this.ruleForm.category!=null) {
          this.modelList();
        }
      }
    },
  },
  components: {
    DiluUploadImg
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd"],
  methods: {
    onChange() {
      this.ruleForm.model=null;
      this.modelList();
    },
    protocolList() {
      var params={
        pageNum:1,
        pageSize:500
      };
      this.request.protocolList(params).then((res) => {
          if (res.code==0) {
            this.proList=[];
            for (var i=0; i<res.data.length; i++) {
              var item={
                id: res.data[i].id,
                name: res.data[i].name+"|"+res.data[i].codec
              };
              this.proList.push(item);
            }
          }
      });
    },
    categoryList() {
       var params={
        pageNum: 1,        
        pageSize: 200, 
       }
       this.request.categoryList(params).then((res) => {
          if (res.code==0) {
            this.catList=res.data;
          }
       });
    },
    modelList() {
      var params = {
        category: this.ruleForm.category,
        pageNum: 1,        
        pageSize: 200, 
      }
      this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.typeList=res.data;
          }
      });
    },
    manufacturerList() {
      var params={
        pageNum: 1,        
        pageSize: 200, 
      }
      this.request.manufacturerList(params).then((res) => {
          if (res.code==0) {
            this.facList=res.data;
          }
      });
    },
    removePhoto(e) {
      const i = this.photoList.findIndex((x) => x.name == e);
      this.photoList.splice(i, 1);
      this.ruleForm.photo = null;
    },
    successPhoto(e) {
      this.ruleForm.photo = e.url;
      this.photoList = e.fileList;
    },
    handleClose() {
      this.ruleForm = {
        id: null,
        category: null,
        model: null,
        manufacturer: null,
        protocol: null,
        status: null,
        name: "",
        photo: "",
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.productAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("productList");
                this.handleClose();
              }
            });
          } else {
            this.request.productEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("productList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>
<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :loading="loading" :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新增协议</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="id" label="协议ID" align="left" width="100"/>
      <el-table-column prop="name" label="协议名称" align="left" width="280"/>
      <el-table-column prop="address" label="从机地址" align="left" width="100"/>
      <el-table-column prop="baudrate" label="波特率" align="left" width="100"/>
      <el-table-column prop="dataBits" label="数据位" align="left" width="100"/>
      <el-table-column prop="parityCheck" label="奇偶校验" align="left" width="100"/>
      <el-table-column prop="stopBits" label="停止位" align="left" width="100"/>
      <el-table-column prop="polling" label="轮询间隔" align="left" width="100"/>
      <el-table-column prop="timeout" label="返回超时" align="left" width="100"/>
      <el-table-column prop="accessLimits" label="访问限制" align="left" width="100"/>
      <el-table-column prop="createdAt" label="创建时间" align="center" width="150" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="dictionary(scope.row)" type="text">字典</el-button>
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button @click="output(scope.row)" type="text">导出</el-button>
            <el-button @click="publish(scope.row)" type="text">发布</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddOriginal :viewVisible="viewVisible" @viewClose="viewClose" :currentData="currentData" @originalList="originalList" :isAdd="isAdd"/>

    <el-dialog title="错误详情" :visible.sync="errorVisible" width="600px" height="600px" padding-top="10px" style="overflow-y:auto;">
      <json-viewer :value="jsonData" :expand-depth=3 :preview-mode="true" :show-array-index="false" style="height:600px;overflow-y:auto;" show-double-quotes copyable/>
    </el-dialog>
    
  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddOriginal from "./module/addOriginal";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         name: "",           // 字典名称
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      loading: false,
      isSearch: false,
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true,
      jsonData: [],
      errorVisible: false,
    };
  },
  components: {
    DiluTable,
    AddOriginal
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.originalList();
  },
  methods: {
    //导出
    output(row) {
      if (row.excelFile==null||row.excelFile=='') {
        this.$message.error("没有可导出的协议字典");
      } else {
        this.request.exportProtocol(row.excelFile,row.name,"xlsx").then();
      }
    },
    //发布
    publish(row) {
      this.loading = true;
      var params={
        protocol: row.id
      }
      this.request.publishProtocol(params).then((res) => {
        this.loading = false;
        if (res.data!=null&&res.data.hasError=="yes") {
          this.jsonData=res.data;
          this.errorVisible=true;
        } else if (res.code==0) {
          this.$message.success("发布成功");
        }
     });
    },
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
       this.searchParams.pageNum = e;
       this.originalList();
    },
    //搜索
    search() {
       this.isSearch = true;
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.originalList();
     },
     //重置
     reset() {
       this.isSearch = false;
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.originalList();
    },
    //新增
    add() {
     this.isAdd = true;
     this.viewVisible = true;
     this.currentData = {};
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
    },
    dictionary(e) {
      this.$router.push({
        path: "/product/protocol/dictionary",
        query: {
          protocol: e.id,
          protocolName: e.name
        },
      });
    },
    //关闭新增编辑菜单弹窗
    viewClose() {
     this.viewVisible = false;
    },
    //查询列表
    originalList() {
     this.request.originalList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
     });
    },
  },
};
</script>

<style scoped>
</style>
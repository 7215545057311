<template>
  <div class="table" style="margin-bottom:30px;">
    <div class="dilu-table">
      <el-table
        ref="dtable"
        id="dilu-table"
        v-loading="loading"
        style="width:100%;"
        :data="list"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ background:'#F5F5F5',color:'#000000' }"
        :height="height"
        :row-key="tableConfig.isTree ? tableConfig.isTreeText : ''"
        @selection-change="handleSelectionChange">
        <template slot="empty">
          <el-empty description="暂无数据" :image-size="100"></el-empty>
        </template>
        <slot></slot>
      </el-table>
    </div>
    <div class="pagination" v-if="tableConfig.isPage">
      <el-pagination background layout="prev, pager, next, total" :total="total" :page-size="pageSize" @current-change="currentChange"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["list", "tableConfig", "total", "pageSize", "height", "loading", "moveLast"],
  watch: {
    list() {
      if (this.moveLast=="yes") {
        setTimeout(()=>{
          this.$nextTick(() => {
            if (this.list!=null&&this.list.length>0) {
              const index=this.list.length;
              const tableBodyWrapper=this.$refs.dtable.$el.querySelector('.el-table__body-wrapper');
              if (tableBodyWrapper) {
                const row = tableBodyWrapper.querySelector(`.el-table__row:nth-child(${index})`);
                if (row) {
                  const { top } = row.getBoundingClientRect();
                  const { top: tableTop } = tableBodyWrapper.getBoundingClientRect();
                  tableBodyWrapper.scrollTop += top - tableTop;
                }
              }
            }
          });
        },200);
      }
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.$watermark.set(user.username, 0, -30);
  },
  methods: {
    handleSelectionChange(e) {
      this.$emit("handleSelectionChange", e);
    },
    //翻页触发
    currentChange(e) {
      this.$emit("currentChange", e);
    },
    //类名
    tableRowClassName({ row, rowIndex }) {
      if (this.tableConfig.stripe) {
        if (row.nodeType == 0) {
          return "selectIndex";
        }
        return "";
      }
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.table {
  flex:none;
  position: relative;
  display: flex;
  flex-direction: column;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.dilu-table {
  flex: 1;
  overflow: auto;
}
</style>